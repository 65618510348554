@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
.navbar {
    background: linear-gradient(90deg, rgb(26, 25, 29) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: sticky;
    /*top: 10;*/
   
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
    margin-left: 100px;
  }
  
  .navbar-logo {
    /*color: #fff;*/
    /*justify-self: start;
    margin-left: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;*/
      justify-self: start;
      width: 60px;
      height: 60px;
      border-radius: 0%;
      overflow: hidden;
      /*margin-top:0px;
      margin-bottom:0px;*/
      margin-left: 0px;
      display: flex;
      cursor: pointer;
      align-items:left;
  }
  
  .fa-typo3 {
    margin-left: 1rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    /*justify-content:flex-start;*/
    margin-right: 0em;
    margin-left: 20em;
  }
  
  .nav-item {
    height: 20px;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #ffffff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: translate(25%, 50%);
              transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translate(-100%, 60%);
              transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home, .about,
.services,
.products,
.clients, .faqs, .contact {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.about {
  background-image: url('/images/put3.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.services {
  background-image: url('/images/put14.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/put15.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.clients {
  background-image: url('/images/put4.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.faqs {
  background-image: url('/images/put9.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: rgb(255, 255, 255);
  font-size: 100px;
}

.contact {
  background-image: url('/images/put2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

        
a, a:active, a:focus {
  color: #6f6f6f;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -ms-transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
}

/* About Us Page */
.sec-title{
  position:relative;
  z-index: 1;
  margin-bottom:60px;
}
        
.sec-title .title{
position: relative;
display: block;
font-size: 18px;
line-height: 24px;
color: #00aeef;
font-weight: 500;
margin-bottom: 15px;
}
        
.sec-title h2{
position: relative;
display: block;
font-size:40px;
line-height: 1.28em;
color: #00aeef;
font-weight: 600;
padding-bottom:18px;
}
        
.sec-title h2:before{
position:absolute;
content:'';
left:0px;
bottom:0px;
width:50px;
height:3px;
background-color:#d1d2d6;
}

.sec-title .text{
position: relative;
font-size: 16px;
line-height: 26px;
color: #848484;
font-weight: 400;
margin-top: 35px;
}
        
.sec-title.light h2{
color: #ffffff;
}
        
.sec-title.text-center h2:before{
left:50%;
margin-left: -25px;
}       
        
.about-section{
position: relative;
padding: 120px 0 70px;
}
        
.about-section .sec-title{
margin-bottom: 45px;
}
        
.about-section .content-column{
position: relative;
margin-bottom: 50px;
}
        
.about-section .content-column .inner-column{
position: relative;
padding-left: 30px;
}
        
.about-section .text{
margin-bottom: 20px;
font-size: 16px;
line-height: 26px;
color: #848484;
font-weight: 400;
text-align: justify;
}

/*Products Page*/

/*body {
  background: #eee;
}*/

.wrapp {
  width: 90%;
  margin: 0 auto;
}

.column {
  width: 30%;
  margin: 4.2% 1.66666667%;
  float: left;
}

img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 48em){
  
  .column {
    width: 46.6666667%;
  }
  
}

@media only screen and (max-width: 37.5em){
  
  .wrapper{
    width: 100%;
  }
  
  .column{
    float: none;
    margin: 4em auto;
    width: 65%;
  }
  
}

* {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    scroll-behavior: smooth;
}

#prods {
    background-color: #ffffff;
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h2 {
    text-align: center;
    font-size: 3rem;
    margin-top: 0;
    margin-bottom: 50px;
}

.prod, .card {
    background-color: white;
    width: 200px;
    text-align: center;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    transition: 0.3s;
}

.prod:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
}

.container {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-column-gap: 50px;
    margin-bottom: 50px;
}

input {
    height: 50px;
    padding: 0 20px;
}

@media screen and (max-width: 700px) {
    .container {
        display: grid;
        grid-template-columns: auto;
        grid-row-gap: 50px;
    }
    
}

/* Services Page */

.section-services {
	font-family: "Poppins", sans-serif;
	background-color: #ffff;
	color: #202020;
	padding-top: 115px;
  padding-bottom: 120px;
  margin-left: 14em;
}

.section-services .header-section {
	margin-bottom: 34px;
}

.section-services .header-section .title {
	position: relative;
    padding-bottom: 14px;
    margin-bottom: 25px;
	font-weight: 700;
    font-size: 32px;
}

.section-services .header-section .title:before {
	content: " ";
	position: absolute;
	bottom: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	width: 50px;
	height: 3px;
	background-color: #00aeef;
    border-radius: 3px;
}

.section-services .header-section .title:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 50%;
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
	width: 10px;
	height: 3px;
	background-color: #504f93;
    border-radius: 3px;
}

.section-services .header-section .description {
	font-size: 17px;
  color: #282828;
  margin-left: 30px;
  text-align: left;
}

.section-services .single-service {
    position: relative;
    margin-top: 30px;
    background-color: rgb(207, 207, 207);
    border-radius: 10px;
    padding: 40px 30px;
    overflow: hidden;
}

.section-services .single-service .content {
	position: relative;
	z-index: 20;
}

.section-services .single-service .content .p{
  font-size: large;
}

.section-services .single-service .circle-before {
    position: absolute;
    top: 0;
    right: 0px;
    -webkit-transform: translate(40%, -40%);
            transform: translate(40%, -40%);
    width: 150px;
    height: 150px;
    background-color: #00aeef;
    border: 6px solid #504f93;
    border-radius: 50%;
    opacity: 0.5;
    z-index: 10;
    transition: all .6s;
}

.section-services .single-service:hover .circle-before {
	width: 100%;
	height: 100%;
	-webkit-transform: none;
	        transform: none;
	border: 0;
	border-radius: 0;
	opacity: 1;
}

.section-services .single-service .icon {
	display: inline-block;
	margin-bottom: 26px;
    width: 70px;
    height: 70px;
    background-color: #00aeef;
    border-radius: 5px;
    line-height: 70px;
    text-align: center;
    color: #fff;
    font-size: 30px;
    transition: all .3s;
}

.section-services .single-service:hover .icon {
	background-color: #fff;
	color: #00aeef;
}

.section-services .single-service .title {
    margin-bottom: 18px;
	font-weight: 700;
    font-size: 23px;
    transition: color .3s;
}

.section-services .single-service:hover .title {
	color: #fff;
}

.section-services .single-service .description {
    margin-bottom: 20px;
    font-size: 17px;
    transition: color .3s;
}

.section-services .single-service:hover .description {
	color: #fff;
}

.section-services .single-service a {
	position: relative;
	font-size: 18px;
    color: #202020;
    text-decoration: none;
    font-weight: 500;
    transition: color .3s;
}

.section-services .single-service:hover a {
	color: #fff;
}

.section-services .single-service a:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 1px;
	background-color: #00aeef;
	transition: background-color .3s;
}

.section-services .single-service:hover a:after {
	background-color: #fff;
}

/* Contact Us Page */

.footer_get_touch_outer {
  margin-top: 80px;
}
.cont {
  width: 95%;
  max-width: 1140px;
  margin: auto;
}
.grid-70-30 {
  display: grid;
  grid-template-columns: 70% 30%;
}
.get_form_inner {
  display: block;
  padding: 50px 40px;
  background: #00aeef;
  box-shadow: -4px -2px 20px -7px #9d9fa2;
}
input[type="text"], input[type="text"], input[type="email"], input[type="tel"] {
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  color: #333;
  height: 42px;
  padding: 0 0 0 20px;
  width: 100%;
  outline: 0;
}
.grid-50-50 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.grid-full {
  margin: 20px 0;
}
textarea {
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  color: #333;
  padding: 12px 0 0 20px;
  width: 100%;
  outline: 0;
  margin-bottom: 20px;
}
.get_form_inner_text h3 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 40px;
}
input[type="submit"] {
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  background: transparent;
  border: 2px solid;
  font-weight: 500;
  padding: 10px 20px;
  outline: 0;
  font-weight: 700;
  cursor: pointer;
  color: #ffff;
  margin-left: 19em;
  transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -moz-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -ms-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
input[type="submit"]:hover {
  background-color: #00b359;
  border-color: #00b359;
  color: rgb(255, 255, 255);
}
.get_say_form {
  display: inline-block;
  padding: 45px 0 25px 20px;
  /*background: #162b65;*/
  background: #c3c3c3 ;
  position: relative;
}
.get_say_form h5 {
  color: rgb(0, 0, 0);
  font-size: 26px;
  margin: 0 20px 40px;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.get_say_info_sec i {
  color: rgb(0, 0, 0);
  font-size: 25px;
  margin-left: 7px;
}
.get_say_info_sec > li {
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
  margin-bottom: 40px;
}
.get_say_info_sec > li a {
  width: 100%;
  display: block;
  padding: 15px 25px;
  color: #000000;
  font-size: 16px;
  text-decoration: unset;
  font-weight: 500;
  /*background: #012154;*/
  background: #dfdfdf;
  border-radius: 5px 0 0 5px;
  transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -moz-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -ms-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.get_say_info_sec > li a:hover {
  background-color: #00b359;
}

.get_say_form .contact-logo{
  justify-self: start;
  width: 150px;
  height: 150px;
  border-radius: 0%;
  overflow: hidden;
  margin-left: 5em;
  display: flex;
  cursor: pointer;
  align-items:left;
}

/* Clients */

.contain {
  width: 100%;
  height: 100%;
  padding: 0px 8%;  
}

.contain h1 {
  text-align: center;
  padding-top: 10%;
  margin-bottom: 60px;
  font-weight: 600;
  position: relative;
  font-family: Book Antiqua,Palatino,Palatino Linotype,Palatino LT STD,Georgia,serif; 
}

.contain h1::after {
  content: '';
  background: #00aeef;
  width: 100px;
  height: 5px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.rows {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 20px;
}

.serv {
  text-align: center;
  padding: 25px 10px;
  border-radius: 5px;
  font-size: 3px;
  cursor: pointer;
  background: transparent;
  transition: background 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, background 0.5s;
  transition: transform 0.5s, background 0.5s, -webkit-transform 0.5s;
  
}

.serv i {
  font-size: 40px;
  margin-bottom: 10px;
  color: #00aeef;
}

.serv p{
  font-weight: 300;
  margin-bottom: 8px;
  font-family: Book Antiqua,Palatino,Palatino Linotype,Palatino LT STD,Georgia,serif; 
  font-size: medium;
}

.serv h2 {
  font-weight: 600;
  margin-bottom: 8px;
  font-family: Book Antiqua,Palatino,Palatino Linotype,Palatino LT STD,Georgia,serif; 
  font-size: large;
}

.serv:hover {
  background: #00aeef;
  color: #fff;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.serv:hover i {
  color: #fff;
}

/* FAQs */

.faq-title {
  font-size: 2em;
  margin: 0.4em 0;
}

div.seperator {
  width: 7.5em;
  background-color: #00aeef;
  height: 0.17em;
  margin-left: -1.8em;
}

.faq-list > div {
  border-bottom: 0.07em solid #ededed;
  padding: 1.5em 0em;
} 

.faq-list > div:last-child {
  border: unset;
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

summary {
  font-size: 1.4em;
  font-weight: bold;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none; 
  user-select: none;
  transition: all 0.3s ease;
}

summary:hover {
  color: #00aeef;
}

details[open] summary ~ * {
  -webkit-animation: sweep .5s ease-in-out;
          animation: sweep .5s ease-in-out;
}

@-webkit-keyframes sweep {
  0%    {opacity: 0; margin-left: -10px}
  100%  {opacity: 1; margin-left: 55px}
}

@keyframes sweep {
  0%    {opacity: 0; margin-left: -10px}
  100%  {opacity: 1; margin-left: 55px}
}

details[open] summary {
  color: #00aeef;
}

details[open] p {
  border-left: 2px solid #00aeef;
  margin-left: 55px;
  padding-left: 25px;
  opacity: 100;
  transition: all 3s ease;
}

details[open] summary:after {
  content: "-";
  font-size: 3.2em;
  margin: -33px 0.35em 0 0;
  font-weight: 200;
}

.faq-body {
  width: 70em;
  margin: 4em auto;
  box-shadow: 0px 0px 16px #5b5b5b;
  border-radius: 0.2em;
  padding: 5em;
  background-color: #fff;
}

.faq-list {
  width: 55em;
  margin: 1em auto;
  padding: 2em 0;
}

summary::-webkit-details-marker {
  display: none;
}

summary:after {
  background: transparent;
  border-radius: 0.3em;
  content: "+";
  color: #00aeef;
  float: left;
  font-size: 1.8em;
  font-weight: bold;
  margin: -0.3em 0.65em 0 0;
  padding: 0;
  text-align: center;
  width: 25px;
}
  .cards {
    padding: 4rem;
    background: rgb(255, 255, 255);
  }
  
  .cards p {
    text-align: justify;
    margin-bottom: 2em;
    margin-top: 1em;
    font-family: Georgia,Times,Times New Roman,serif; 
    font-size: 20px;

  }

  h1{
    text-align: center;
  }

  .cards h2{
    text-align: center;
    
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    -webkit-animation-name: fade-img;
            animation-name: fade-img;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }
/*video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }*/
  
  
  .hero-container {
    background: url('/images/sky.jpg') center center/cover no-repeat; 
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container > h1 {
    color: rgb(255, 255, 255);
    font-size: 100px;
    margin-top: -100px;
  }

  .hero-container > h2 {
    color: rgb(255, 255, 255);
    font-size: 100px;
    margin-top: -100px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .hero-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }
/*.footer-container {
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }*/
  
 /*.website-rights {
    color: #fff;
    margin-bottom: 16px;
  }

ul{
    margin: 0px;
    padding: 0px;
}

.footer-section {
  background: #151414;
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display:grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  list-style: none;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 768px) {
  }*/

  .footer {
    background: linear-gradient(90deg, rgb(26, 25, 29) 0%, rgb(26, 23, 23) 100%);
    width: 100%;
    text-align: left;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 16px;
    padding: 50px;
    margin-top: 50px;
  }
  
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    display: inline-block;
    vertical-align: top;
  }
  
  
  /* footer left*/
  
  .footer .footer-left {
    width: 33%;
    padding-right: 15px;
  }
  
  .footer .aboutu {
    line-height: 20px;
    color: #ffffff;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
    text-align: justify;
  }
  
  .footer .aboutu span {
    display: block;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    
  }
   
  /* footer center*/
  
  .footer .footer-center {
    width: 30%;
  }
  
  .footer .footer-center i {
    background-color: #33383b;
    color: #ffffff;
    font-size: 25px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
  }
  
  .footer .footer-center i.fa-envelope {
    font-size: 17px;
    line-height: 38px;
  }
  
  .footer .footer-center p {
    display: inline-block;
    color: #ffffff;
    vertical-align: middle;
    margin: 0;
  }
  
  .footer .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
  }
  
  .footer .footer-center p a {
    color: #0099ff;
    text-decoration: none;
  }
  
  
  /* footer right*/
  
  .footer .footer-right {
    width: 35%;
  }
  
  .footer h2 {
    color: #ffffff;
    font-size: 36px;
    font-weight: normal;
    margin: 0;
  }
  
  .footer h2 span {
    color: #0099ff;
  }
  
  .footer .menu {
    color: #ffffff;
    margin: 20px 0 12px;
    padding: 0;
  }
  
  .footer .menu a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
  }
  
  .footer .menu a:hover {
    color: #0099ff;
  }
  
  .footer .name {
    color: #0099ff;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
  }
  
  @media (max-width: 767px) {
    .footer {
      font-size: 14px;
    }
    .footer .footer-left,
    .footer .footer-center,
    .footer .footer-right {
      display: block;
      width: 100%;
      margin-bottom: 40px;
      text-align: center;
    }
    .footer .footer-center i {
      margin-left: 0;
    }
  }
