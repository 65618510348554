/*.footer-container {
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }*/
  
 /*.website-rights {
    color: #fff;
    margin-bottom: 16px;
  }

ul{
    margin: 0px;
    padding: 0px;
}

.footer-section {
  background: #151414;
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display:grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  list-style: none;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 768px) {
  }*/

  .footer {
    background: linear-gradient(90deg, rgb(26, 25, 29) 0%, rgb(26, 23, 23) 100%);
    width: 100%;
    text-align: left;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 16px;
    padding: 50px;
    margin-top: 50px;
  }
  
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    display: inline-block;
    vertical-align: top;
  }
  
  
  /* footer left*/
  
  .footer .footer-left {
    width: 33%;
    padding-right: 15px;
  }
  
  .footer .aboutu {
    line-height: 20px;
    color: #ffffff;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
    text-align: justify;
  }
  
  .footer .aboutu span {
    display: block;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    
  }
   
  /* footer center*/
  
  .footer .footer-center {
    width: 30%;
  }
  
  .footer .footer-center i {
    background-color: #33383b;
    color: #ffffff;
    font-size: 25px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
  }
  
  .footer .footer-center i.fa-envelope {
    font-size: 17px;
    line-height: 38px;
  }
  
  .footer .footer-center p {
    display: inline-block;
    color: #ffffff;
    vertical-align: middle;
    margin: 0;
  }
  
  .footer .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
  }
  
  .footer .footer-center p a {
    color: #0099ff;
    text-decoration: none;
  }
  
  
  /* footer right*/
  
  .footer .footer-right {
    width: 35%;
  }
  
  .footer h2 {
    color: #ffffff;
    font-size: 36px;
    font-weight: normal;
    margin: 0;
  }
  
  .footer h2 span {
    color: #0099ff;
  }
  
  .footer .menu {
    color: #ffffff;
    margin: 20px 0 12px;
    padding: 0;
  }
  
  .footer .menu a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
  }
  
  .footer .menu a:hover {
    color: #0099ff;
  }
  
  .footer .name {
    color: #0099ff;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
  }
  
  @media (max-width: 767px) {
    .footer {
      font-size: 14px;
    }
    .footer .footer-left,
    .footer .footer-center,
    .footer .footer-right {
      display: block;
      width: 100%;
      margin-bottom: 40px;
      text-align: center;
    }
    .footer .footer-center i {
      margin-left: 0;
    }
  }