* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home, .about,
.services,
.products,
.clients, .faqs, .contact {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.about {
  background-image: url('/images/put3.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.services {
  background-image: url('/images/put14.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/put15.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.clients {
  background-image: url('/images/put4.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.faqs {
  background-image: url('/images/put9.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: rgb(255, 255, 255);
  font-size: 100px;
}

.contact {
  background-image: url('/images/put2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

        
a, a:active, a:focus {
  color: #6f6f6f;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -ms-transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
}

/* About Us Page */
.sec-title{
  position:relative;
  z-index: 1;
  margin-bottom:60px;
}
        
.sec-title .title{
position: relative;
display: block;
font-size: 18px;
line-height: 24px;
color: #00aeef;
font-weight: 500;
margin-bottom: 15px;
}
        
.sec-title h2{
position: relative;
display: block;
font-size:40px;
line-height: 1.28em;
color: #00aeef;
font-weight: 600;
padding-bottom:18px;
}
        
.sec-title h2:before{
position:absolute;
content:'';
left:0px;
bottom:0px;
width:50px;
height:3px;
background-color:#d1d2d6;
}

.sec-title .text{
position: relative;
font-size: 16px;
line-height: 26px;
color: #848484;
font-weight: 400;
margin-top: 35px;
}
        
.sec-title.light h2{
color: #ffffff;
}
        
.sec-title.text-center h2:before{
left:50%;
margin-left: -25px;
}       
        
.about-section{
position: relative;
padding: 120px 0 70px;
}
        
.about-section .sec-title{
margin-bottom: 45px;
}
        
.about-section .content-column{
position: relative;
margin-bottom: 50px;
}
        
.about-section .content-column .inner-column{
position: relative;
padding-left: 30px;
}
        
.about-section .text{
margin-bottom: 20px;
font-size: 16px;
line-height: 26px;
color: #848484;
font-weight: 400;
text-align: justify;
}

/*Products Page*/

/*body {
  background: #eee;
}*/

.wrapp {
  width: 90%;
  margin: 0 auto;
}

.column {
  width: 30%;
  margin: 4.2% 1.66666667%;
  float: left;
}

img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 48em){
  
  .column {
    width: 46.6666667%;
  }
  
}

@media only screen and (max-width: 37.5em){
  
  .wrapper{
    width: 100%;
  }
  
  .column{
    float: none;
    margin: 4em auto;
    width: 65%;
  }
  
}


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    scroll-behavior: smooth;
}

#prods {
    background-color: #ffffff;
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h2 {
    text-align: center;
    font-size: 3rem;
    margin-top: 0;
    margin-bottom: 50px;
}

.prod, .card {
    background-color: white;
    width: 200px;
    text-align: center;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    transition: 0.3s;
}

.prod:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.container {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-column-gap: 50px;
    margin-bottom: 50px;
}

input {
    height: 50px;
    padding: 0 20px;
}

@media screen and (max-width: 700px) {
    .container {
        display: grid;
        grid-template-columns: auto;
        grid-row-gap: 50px;
    }
    
}

/* Services Page */

.section-services {
	font-family: "Poppins", sans-serif;
	background-color: #ffff;
	color: #202020;
	padding-top: 115px;
  padding-bottom: 120px;
  margin-left: 14em;
}

.section-services .header-section {
	margin-bottom: 34px;
}

.section-services .header-section .title {
	position: relative;
    padding-bottom: 14px;
    margin-bottom: 25px;
	font-weight: 700;
    font-size: 32px;
}

.section-services .header-section .title:before {
	content: " ";
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 50px;
	height: 3px;
	background-color: #00aeef;
    border-radius: 3px;
}

.section-services .header-section .title:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 50%;
    transform: translateX(30px);
	width: 10px;
	height: 3px;
	background-color: #504f93;
    border-radius: 3px;
}

.section-services .header-section .description {
	font-size: 17px;
  color: #282828;
  margin-left: 30px;
  text-align: left;
}

.section-services .single-service {
    position: relative;
    margin-top: 30px;
    background-color: rgb(207, 207, 207);
    border-radius: 10px;
    padding: 40px 30px;
    overflow: hidden;
}

.section-services .single-service .content {
	position: relative;
	z-index: 20;
}

.section-services .single-service .content .p{
  font-size: large;
}

.section-services .single-service .circle-before {
    position: absolute;
    top: 0;
    right: 0px;
    transform: translate(40%, -40%);
    width: 150px;
    height: 150px;
    background-color: #00aeef;
    border: 6px solid #504f93;
    border-radius: 50%;
    opacity: 0.5;
    z-index: 10;
    transition: all .6s;
}

.section-services .single-service:hover .circle-before {
	width: 100%;
	height: 100%;
	transform: none;
	border: 0;
	border-radius: 0;
	opacity: 1;
}

.section-services .single-service .icon {
	display: inline-block;
	margin-bottom: 26px;
    width: 70px;
    height: 70px;
    background-color: #00aeef;
    border-radius: 5px;
    line-height: 70px;
    text-align: center;
    color: #fff;
    font-size: 30px;
    transition: all .3s;
}

.section-services .single-service:hover .icon {
	background-color: #fff;
	color: #00aeef;
}

.section-services .single-service .title {
    margin-bottom: 18px;
	font-weight: 700;
    font-size: 23px;
    transition: color .3s;
}

.section-services .single-service:hover .title {
	color: #fff;
}

.section-services .single-service .description {
    margin-bottom: 20px;
    font-size: 17px;
    transition: color .3s;
}

.section-services .single-service:hover .description {
	color: #fff;
}

.section-services .single-service a {
	position: relative;
	font-size: 18px;
    color: #202020;
    text-decoration: none;
    font-weight: 500;
    transition: color .3s;
}

.section-services .single-service:hover a {
	color: #fff;
}

.section-services .single-service a:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 1px;
	background-color: #00aeef;
	transition: background-color .3s;
}

.section-services .single-service:hover a:after {
	background-color: #fff;
}

/* Contact Us Page */

.footer_get_touch_outer {
  margin-top: 80px;
}
.cont {
  width: 95%;
  max-width: 1140px;
  margin: auto;
}
.grid-70-30 {
  display: grid;
  grid-template-columns: 70% 30%;
}
.get_form_inner {
  display: block;
  padding: 50px 40px;
  background: #00aeef;
  box-shadow: -4px -2px 20px -7px #9d9fa2;
}
input[type="text"], input[type="text"], input[type="email"], input[type="tel"] {
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  color: #333;
  height: 42px;
  padding: 0 0 0 20px;
  width: 100%;
  outline: 0;
}
.grid-50-50 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.grid-full {
  margin: 20px 0;
}
textarea {
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  color: #333;
  padding: 12px 0 0 20px;
  width: 100%;
  outline: 0;
  margin-bottom: 20px;
}
.get_form_inner_text h3 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 40px;
}
input[type="submit"] {
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  background: transparent;
  border: 2px solid;
  font-weight: 500;
  padding: 10px 20px;
  outline: 0;
  font-weight: 700;
  cursor: pointer;
  color: #ffff;
  margin-left: 19em;
  transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -moz-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -ms-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
input[type="submit"]:hover {
  background-color: #00b359;
  border-color: #00b359;
  color: rgb(255, 255, 255);
}
.get_say_form {
  display: inline-block;
  padding: 45px 0 25px 20px;
  /*background: #162b65;*/
  background: #c3c3c3 ;
  position: relative;
}
.get_say_form h5 {
  color: rgb(0, 0, 0);
  font-size: 26px;
  margin: 0 20px 40px;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.get_say_info_sec i {
  color: rgb(0, 0, 0);
  font-size: 25px;
  margin-left: 7px;
}
.get_say_info_sec > li {
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
  margin-bottom: 40px;
}
.get_say_info_sec > li a {
  width: 100%;
  display: block;
  padding: 15px 25px;
  color: #000000;
  font-size: 16px;
  text-decoration: unset;
  font-weight: 500;
  /*background: #012154;*/
  background: #dfdfdf;
  border-radius: 5px 0 0 5px;
  transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -moz-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -ms-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition: background 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.get_say_info_sec > li a:hover {
  background-color: #00b359;
}

.get_say_form .contact-logo{
  justify-self: start;
  width: 150px;
  height: 150px;
  border-radius: 0%;
  overflow: hidden;
  margin-left: 5em;
  display: flex;
  cursor: pointer;
  align-items:left;
}

/* Clients */

.contain {
  width: 100%;
  height: 100%;
  padding: 0px 8%;  
}

.contain h1 {
  text-align: center;
  padding-top: 10%;
  margin-bottom: 60px;
  font-weight: 600;
  position: relative;
  font-family: Book Antiqua,Palatino,Palatino Linotype,Palatino LT STD,Georgia,serif; 
}

.contain h1::after {
  content: '';
  background: #00aeef;
  width: 100px;
  height: 5px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.rows {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 20px;
}

.serv {
  text-align: center;
  padding: 25px 10px;
  border-radius: 5px;
  font-size: 3px;
  cursor: pointer;
  background: transparent;
  transition: transform 0.5s, background 0.5s;
  
}

.serv i {
  font-size: 40px;
  margin-bottom: 10px;
  color: #00aeef;
}

.serv p{
  font-weight: 300;
  margin-bottom: 8px;
  font-family: Book Antiqua,Palatino,Palatino Linotype,Palatino LT STD,Georgia,serif; 
  font-size: medium;
}

.serv h2 {
  font-weight: 600;
  margin-bottom: 8px;
  font-family: Book Antiqua,Palatino,Palatino Linotype,Palatino LT STD,Georgia,serif; 
  font-size: large;
}

.serv:hover {
  background: #00aeef;
  color: #fff;
  transform: scale(1.05);
}

.serv:hover i {
  color: #fff;
}

/* FAQs */

.faq-title {
  font-size: 2em;
  margin: 0.4em 0;
}

div.seperator {
  width: 7.5em;
  background-color: #00aeef;
  height: 0.17em;
  margin-left: -1.8em;
}

.faq-list > div {
  border-bottom: 0.07em solid #ededed;
  padding: 1.5em 0em;
} 

.faq-list > div:last-child {
  border: unset;
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

summary {
  font-size: 1.4em;
  font-weight: bold;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; 
  user-select: none;
  transition: all 0.3s ease;
}

summary:hover {
  color: #00aeef;
}

details[open] summary ~ * {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0%    {opacity: 0; margin-left: -10px}
  100%  {opacity: 1; margin-left: 55px}
}

details[open] summary {
  color: #00aeef;
}

details[open] p {
  border-left: 2px solid #00aeef;
  margin-left: 55px;
  padding-left: 25px;
  opacity: 100;
  transition: all 3s ease;
}

details[open] summary:after {
  content: "-";
  font-size: 3.2em;
  margin: -33px 0.35em 0 0;
  font-weight: 200;
}

.faq-body {
  width: 70em;
  margin: 4em auto;
  box-shadow: 0px 0px 16px #5b5b5b;
  border-radius: 0.2em;
  padding: 5em;
  background-color: #fff;
}

.faq-list {
  width: 55em;
  margin: 1em auto;
  padding: 2em 0;
}

summary::-webkit-details-marker {
  display: none;
}

summary:after {
  background: transparent;
  border-radius: 0.3em;
  content: "+";
  color: #00aeef;
  float: left;
  font-size: 1.8em;
  font-weight: bold;
  margin: -0.3em 0.65em 0 0;
  padding: 0;
  text-align: center;
  width: 25px;
}